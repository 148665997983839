exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-annex-js": () => import("./../../../src/pages/annex.js" /* webpackChunkName: "component---src-pages-annex-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-exhibits-js": () => import("./../../../src/pages/exhibits.js" /* webpackChunkName: "component---src-pages-exhibits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-school-activities-js": () => import("./../../../src/pages/school-activities.js" /* webpackChunkName: "component---src-pages-school-activities-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-tickets-old-js": () => import("./../../../src/pages/tickets-old.js" /* webpackChunkName: "component---src-pages-tickets-old-js" */),
  "component---src-templates-activities-js": () => import("./../../../src/templates/activities.js" /* webpackChunkName: "component---src-templates-activities-js" */)
}

